<template>
  <!-- eslint-disable max-len-->
  <div class="f-overlay">
    <div class="modal" :style="'width: ' + modalWidth">
      <div class="header">
        <slot class="title" name="header"></slot>
        <div class="close">
          <svg
              @click="$emit('close')"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path
                d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
            />
          </svg>
        </div>
      </div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
      <div class="footer-full-btn">
        <slot name="footer-full-btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: ['width'],
  computed: {
    modalWidth() {
      if (this.width) return this.width + 'px';
      return '450px';
    }
  }
};
</script>

<style lang="scss" scoped>
.f-overlay {
  background: rgba(11, 11, 11, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9998;
  top: 0;
  left: 0;
}

.modal {
  width: 450px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  // box-shadow: 0 0 0 1px rgba(0, 24, 49, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
  inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  max-height: 550px;
  overflow-y: scroll;

  .header {
    background: none;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 9;
    padding: 16px;

    .close {
      cursor: pointer;
      svg {
        height: 20px;
        fill: #464646;
        transition: all 0.2s ease;
        display: block;
        &:hover {
          transition: all 0.2s ease;
          fill: #000;
        }
      }
    }

    h6 {
      margin: 0;
      flex: 1;
    }
  }

  .content {
    padding: 0 16px 16px;

    p {
      word-wrap: break-word;
      line-height: 25px;
      font-size: 1.6rem;
      color: var(--black);
    }
  }

  .footer {
    float: right;
    padding: 0 16px 16px;

    button {
      margin-left: 10px;

      &:first-child {
        margin-left: -10px;
      }
    }
  }
}
@media all and (max-width: 479px) {
  .modal {
    width: 300px;
  }
}
</style>
