<template>
  <div class="load-screen">
    <div class="outer loading">
      <div class="middle">
        <div class="inner">
          <svg class="circle-loader progress" width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="15" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullScreenLoader"
}
</script>

<style scoped>
/* Loader */
.load-screen {
  position: fixed;
  height: calc(100vh - 6.1rem);
  width: 100vw;
  background: #fff;
  z-index: 999;
  top: 6.1rem;
  left: 0;
}

.outer {
  display: table;
  position: absolute;
  height: 50%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  /*whatever width you want*/
}

.circle-loader {
  margin-left: 40%;
  fill: transparent;
  stroke: #e6e6e6;
  stroke-width: 4;
  animation: dash 1s ease infinite, rotate 2s linear infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
